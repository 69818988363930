<template>
	 <div class="col-md-2 col-sm-2 mt-10 boxdanhmucright" v-if="banner_list!=null">
         

          <div class="mrb-20" v-for="(v,k) in banner_list" :key="k" >
            <a v-if="v.description.indexOf('http')>-1" :href="v.description" :title="v.name" target="_blank">
              <img
                         
                          :src="my_url + '' + v.picture"
                          :alt="v.name"
                         
                        />
            </a>
             <a v-else :href="'/'+$i18n.locale+''+ v.description" :title="v.name">
              <img
                         
                          :src="my_url + '' + v.picture"
                          :alt="v.name"
                         
                        />
            </a>
          </div>
          
        </div>
</template>
  <script>
    export default{
       data(){
        return {
          my_url:window.my_url,
        }
       },
       props:['banner_list']
    }
  </script>