<template>
	 <footer class="footer anim-object2">
	    <div class="footer-main-area" data-background="/layout/images/footer-bg.png">
	      <div class="container">
	        <div class="row">
	          <div class="col-xl-4 col-lg-6 col-md-6">
	            <div class="widget footer-widget">
	              <h5 class="widget-title text-white mrb-30">{{$t('general.contact')}}</h5>

	              <div class="mrb-25" v-if="$i18n.locale=='vn'">
	                <p class="text-light-gray">
	                  {{General.address}}
	                </p>
	                <div class="mrb-10">
	                  <a :href="'tel:'+General.phone" class="text-light-gray">
	                    <i class="fas fa-phone-alt mrr-10"></i>
	                    {{General.phone}}
	                  </a>
	                </div>
	                <div class="mrb-10">
	                  <a :href="'mailto:'+General.email" class="text-light-gray">
	                    <i class="fas fa-envelope mrr-10"></i>
	                    {{General.email}}
	                  </a>
	                </div>
	                <div class="mrb-0">
	                  <a href="https://www.benxemiendong.com.vn/" class="text-light-gray">
	                    <i class="fas fa-globe mrr-10"></i>https://www.benxemiendong.com.vn/
	                  </a>
	                </div>
	              </div>
	              <div class="mrb-25" v-else>
	                <p class="text-light-gray">
	                  {{General.address}}

	                </p>
	                <div class="mrb-10">
	                  <a :href="'tel:'+General.hotline"  class="text-light-gray">
	                    <i class="fas fa-phone-alt mrr-10"></i>
	                    {{General.hotline}}
	                  </a>
	                </div>
	                <div class="mrb-10">
	                  <a :href="'mailto:'+General.email" class="text-light-gray">
	                    <i class="fas fa-envelope mrr-10"></i>
	                    {{General.email}}
	                  </a>
	                </div>
	                <div class="mrb-0">
	                  <a href="https://www.benxemiendong.com.vn/" class="text-light-gray">
	                    <i class="fas fa-globe mrr-10"></i>https://www.benxemiendong.com.vn/
	                  </a>
	                </div>
	              </div>
	              <!-- <ul class="social-list">
	                <li><a href="#" title="#"><i class="fab fa-facebook-f"></i></a></li>
	              </ul> -->
	            </div>
	          </div>
	          <div class="col-xl-8 col-lg-6 col-md-6">
	            <div class="row">
	              <div class="col-12 col-sm-4 col-md-4 col-lg-4">


	                <div class="widget footer-widget" v-if="Info!=null">
	                  <h5 class="widget-title text-white mrb-30">{{Info.name}}</h5>
	                  <ul class="footer-widget-list">
	                    			<li v-for="(n,kn) in MyNews.filter(n => n.parentId== Info.id && n.isMenu=='1' ).sort((a, b) => a.orderBy - b.orderBy )" :key="kn">
                              <a :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:n.slug}}).href"   :title="n.name">
                                  {{n.name}}
                              </a>
                            </li>
	                 
	                  </ul>
	                </div>


	              </div>
	              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
	                <div class="widget footer-widget">
	                  <h5 class="widget-title text-white mrb-30">
	                  	{{$t('general.utilities')}}
	                  </h5>
	                  <ul class="footer-widget-list">
	                    <li><a :href="$router.resolve({name:'vehicle',params:{locale:$i18n.locale}}).href"   title="$t('vehicle.name')">
	                    		{{$t('vehicle.name')}}
	                    </a></li>

	                    <li v-for="(p,k) in Page" :key="k" >	
	                    		<a :href="$router.resolve({name:'page.detail',params:{locale:$i18n.locale,slug:p.slug}}).href"  :title="p.name">
	                    				{{p.name}}
	                    		</a>
	                    </li>
	                 	
	                    <li>
	                    	 <a :href="'/'+$i18n.locale+'/so-do-tuyen-xe-bus'" :title="$t('general.bus_footer')">
	                    		{{$t('general.bus_footer')}}
	                   		 </a>
	                  	</li>


	                    <li>
	                    	 <a :href="'/'+$i18n.locale+'/tin-tuyen-dung'" :title="$t('general.recruitment')">
	                    		{{$t('general.recruitment')}}
	                   		 </a>
	                  	</li>

	                   		 <li class="">
                          <a :href="$router.resolve({name:'contact',params:{locale:$i18n.locale}}).href"   :title="$t('general.contact_us')">{{$t('general.contact_us')}}</a>
                        </li>
                        <li class="">
                          <a :href="$router.resolve({name:'awnser',params:{locale:$i18n.locale}}).href"  :title="$t('general.contributions')">{{$t('general.contributions')}}</a>
                        </li>
	                  </ul>
	                </div>
	              </div>
	              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
	                <div class="widget footer-widget">
	                  <h5 class="widget-title text-white mrb-30">{{$t('general.connect')}}</h5>
	                  <ul class="footer-widget-list">
	                    <li><a href="https://vexemiendong.com.vn/Trang_Dat_Ve.aspx" :title="$t('general.order')">
	                    			{{$t('general.order')}}
	                    		</a>
	                    </li>
	                  	<li v-if="Page1!=null" >
	                  			<a :href="$router.resolve({name:'page.detail',params:{locale:$i18n.locale,slug:Page1.slug}}).href"  :title="Page1.name">
	                    				{{Page1.name}}
	                    	</a>

	                  	</li>
	                    <li v-for="(c,k) in Connection" :key="k">
	                    				<a :href="c.links" :title="c.name">
	                    						{{c.name}}
	                    				</a>
	                    </li>
	                   
	                   
	                    <li><a href="javascript:;" title="#">{{$t("general.visit")}}: {{Visits.total}}</a></li>
	                    <li><a href="javascript:;" title="#">{{$t("general.online")}}: {{Visits.online}}</a></li>
	                  </ul>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	    <div class="footer-bottom-area">
	      <div class="container footer-border-top pdt-10 pdb-10">
	        <div class="row">
	          <div class="col-xl-12">
	            <div class="text-center copyright">
	              <span class="text-light-gray">
	                {{$t('general.copyright')}}
	                <a class="text-primary-color" target="_blank" href=""> {{$t("general.name")}}</a>
	             
	              </span><br />
	              <span class="sp_text">
	                {{$t('general.policy')}}
	              </span>
	            </div>
	          </div>
	        </div>
	      </div>
    </div>
  </footer>
</template>
<script>
	import axios from 'axios';
  export default{
    data(){
      return {
       	Info:null,
       	Page:null,
       	Page1:null,
       	Connection:null,
       	Visits:{
       				total:1025393,
       				online:Math.floor(Math.random() * 10)+3,
       				date: (new Date()).getDate()
       	}
      }
    },
    props:['General',"Categories","MyNews"],
    mounted(){
    	// if(!localStorage.date){
    	// 	localStorage.date = this.Visits.date;
    	// 	localStorage.total=this.Visits.total;
    	// }

    	// if(localStorage.date != (new Date()).getDate()){
    		
    	// 		localStorage.total= localStorage.total+Math.floor(Math.random() * 100)+9;
    	// 		localStorage.date= (new Date()).getDate();
    	// }
         		
    	//  this.Visits.total=localStorage.total;

     	 var Info= this.Categories.filter(n => n.slug=='gioi-thieu');
     	
     	 if(Info.length){
     	 		this.Info=Info[0];

     	 }
     	 

     		axios.get(window.my_api+"api/page/get-pages",{
                    headers:{
                        language: this.$i18n.locale
                    }
                  })
                 .then(res=>{
                     
                    this.Page=res.data.data.filter(n=> n.slug!='huong-dan-thu-tuc');

                    var Page1=res.data.data.filter(n=> n.slug=='huong-dan-thu-tuc');
                    if(Page1.length){
                    	this.Page1=Page1[0];
                    }
                 });

                 axios.get(window.my_api+"api/connection/get-connections",{
                    headers:{
                        language: this.$i18n.locale
                    }
                  })
                 .then(res=>{
                     
                    this.Connection=res.data.data;

                 });


                 axios.get(window.location.protocol+"//benxemiendong.com.vn/SendMail/viewer",{
                    headers:{
                        language: this.$i18n.locale
                    }
                  })
                 .then(res=>{
                     console.log(res);
                    this.Visits.total=res.data.total;



                     this.Visits.online=res.data.viewer;

                     if(this.Visits.online*1>10){
                     
                     		axios.get("//benxemiendong.com.vn/SendMail/viewer/clear.php");
                     }

                 });

                


                  
                 
      
    },
    methods:{
      
    }
  }
</script>