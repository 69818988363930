<template>
	 <header class="header-style-two">
    <div class="header-wrapper">
      <div class="header-top-area d-none d-lg-block">

        <div class="container">
          <div class="row">
            <div class="col-lg-9 header-top-left-part">
              <span class="address"><i class="webexflaticon flaticon-email"></i>
                Email: <a  :href="'mailto:'+General.email" :title="General.name">
                 {{General.email}}

                </a>
              </span>
              <span class="phone"><i class="webexflaticon flaticon-phone-1"></i>
                Hotline: <a :href="'tel:'+General.hotline" :title="'Hotline'+General.name">{{General.hotline}}</a>
              </span>
              <span class="phone"><i class="webexflaticon flaticon-phone-1"></i>
                {{$t("general.hotline")}}: <a :href="'tel:'+General.phone1" :title="'Hotline'+General.name">{{General.phone1}}</a>
              </span>
            </div>
            <div class="col-lg-3 header-top-right-part text-right">
              <div class="boxsearch seach-toggle">
                <a href="#" title="#"><i class="webex-icon-Search"></i></a>
              </div>
              <ul class="search-box">
                <li>
                  <form method="get" :action="$router.resolve({name:'search',params:{locale:$i18n.locale}}).href">
                    <div class="form-group">
                      <input type="search" name="s" :placeholder="$t('search.placeholder')" required="required">
                      <button type="submit"><i class="fa fa-search"></i></button>
                    </div>
                  </form>
                </li>
              </ul>
              <ul class="social-links">
                <!-- <li><a href="#" title="#"><i class="fab fa-facebook-f"></i></a></li> -->
                <li v-if="General.social !== undefined"  ><a :href="General.social" title="Youtube "><i class="fab fa-youtube"></i></a></li>
              </ul>
              <div class="language" v-if="$i18n.locale=='vn'">
                <div class="language-btn" >
                  <i class="far1 fa-flag1"></i>
                  Vietnamese
                </div>
                <ul class="language-dropdown">
                  <li><a :href="$router.resolve({params:{locale:'en'}}).href"  title="English">English</a></li>
                </ul>
              </div>

               <div class="language" v-else>

                 <div class="language-btn" >
                  <i class="far2 fa-flag2"></i>
                  English
                </div>
                <ul class="language-dropdown">
                  <li><a :href="$router.resolve({params:{locale:'vn'}}).href"   title="English">Vietnamese</a></li>
                </ul>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header-navigation-area two-layers-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="navbar-brand logo f-left mrt-md-0">
                <a class=""  target="_blank" title="samco" href="http://www.samco.com.vn/">
                  <img id="logo-image" style="width: 140px; margin-top:13px" class="img-center lazy" src="../../assets/logo-samco.jpg" alt="">
                </a>
                <a class="" title="Bến xe miền đông"  :href="$router.resolve({name:'Home',params:{locale:$i18n.locale}}).href">
                  <img id="logo-image" style="width: 100px;" class="img-center lazy" src="../../assets/logo-benxe.jpg" alt="">
                </a>
              </div>
              <div class="mobile-menu-right"></div>
              <div class="header-searchbox-style-two d-none d-xl-block">
                <div class="side-panel side-panel-trigger text-right d-none d-lg-block">
                  <span class="bar1"></span>
                  <span class="bar2"></span>
                  <span class="bar3"></span>
                </div>
              </div>
              <div class="side-panel-content">
                <div class="close-icon">
                  <button><i class="webex-icon-cross"></i></button>
                </div>
                <div class="side-panel-logo mrb-30">
                  <a :href="$router.resolve({name:'Home',params:{locale:$i18n.locale}}).href" title="logo ben xe">
                    <img class="lazy" src="../../assets/default/images/logo_1.png" :alt="General.name" />
                  </a>
                </div>
                <div class="side-info mrb-30">
                  <div class="side-panel-element mrb-25">
                    <!-- <h3 class="mrb-10">Bến xe Miền Đông</h3> -->
                    <ul class="list-items">
                      <li><span class="fa fa-map-marker-alt mrr-10 text-primary-color"></span>
                          {{General.address}}
                      </li>

                      <li><span class="fas fa-phone-alt mrr-10 text-primary-color"></span>Hotline:
                           <a :href="'tel:'+General.hotline" :title="General.name"> {{General.hotline}}</a></li>
                      <li><span class="fas fa-envelope mrr-10 text-primary-color"></span>Email:
                            <a :href="'mailto:'+General.email" >{{General.email}}</a></li>

                      <li><span class="fas fa-phone-alt mrr-10 text-primary-color"></span>{{$t('general.hotline')}}: <a :href="'tel:'+General.phone">
                        {{General.phone}}
                      </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="main-menu f-right">
                <nav id="mobile-menu-right">
                  <ul>
                    <li class="">
                      <a :href="$router.resolve({name:'Home',params:{locale:$i18n.locale}}).href" :title="General.name">
                        {{ $t('general.home')}}
                      </a>
                    </li>
                    <li class="has-sub" v-for="(parent,key) in Parents" :key="key">
                      <a :href="$router.resolve({name: 'news.cate',params:{locale:$i18n.locale,slug:parent.slug}}).href" :title="parent.name">{{parent.name}}</a>
                      <ul class="sub-menu">
                        <li class="has-sub-child" v-for="(v,k) in getChild(parent.id)" :key="k">
                          <a :href="$router.resolve({name: 'news.cate',params:{locale:$i18n.locale,slug:v.slug}}).href"  :title="v.name">
                              {{v.name}}
                          </a>
                          <ul class="sub-menu right-view">
                            <li v-for="(n,kn) in MyNews.filter(n => n.cateId== v.id )" :key="kn">
                              <a :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:n.slug}}).href"   :title="n.name">
                                  {{n.name}}
                              </a>
                            </li>

                          </ul>
                        </li>




                      </ul>
                    </li>

                    <li>
                      <a :href="$router.resolve({name:'question',params:{locale:$i18n.locale}}).href"
                        :title="$t('general.question')">
                          {{$t('general.question')}}
                      </a>
                    </li>
                    <li class="">
                      <a href="https://vexemiendong.com.vn/Trang_Dat_Ve.aspx" target="_blank" :title="$t('general.book')">{{$t('general.book')}}</a>
                    </li>
                    <li class="has-sub right-view">
                      <a :href="$router.resolve({name:'contact',params:{locale:$i18n.locale}}).href" :title="$t('general.contact')">{{$t('general.contact')}}</a>
                      <ul class="sub-menu">
                        <li class="">
                          <a :href="$router.resolve({name:'contact',params:{locale:$i18n.locale}}).href"   :title="$t('general.contact_us')">{{$t('general.contact_us')}}</a>
                        </li>
                        <li class="">
                          <a :href="$router.resolve({name:'awnser',params:{locale:$i18n.locale}}).href"  :title="$t('general.contributions')">{{$t('general.contributions')}}</a>
                        </li>
                      </ul>

                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 marquee1">
              <marquee width="100%" direction="left">
                 {{General.slogan}}
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
  export default{
    data(){
      return {
        social:null,
        Parents:null,
        Child:[]
      }
    },
    props:['General','Categories',"MyNews"],
    created(){
       this.Parents = this.Categories.filter(n => n.status=='1' && n.parentId==null && n.isMenu==1 && n.language.toLocaleLowerCase()==this.$i18n.locale ).sort((a, b) => a.orderBy - b.orderBy );

       for (var i = this.Parents.length - 1; i >= 0; i--) {

           this.Child.push(this.Categories.filter(n => n.parentId==this.Parents[i].id).sort((a, b) => a.orderBy - b.orderBy ) );
       }
      // console.log("Parents",this.Parents);
      // console.log("Child",this.Child);



    },
    
    methods:{
        getChild(id_parent){
            return  this.Categories.filter(n => n.parentId==id_parent).sort((a, b) => a.orderBy - b.orderBy );
        }
    }
  }
</script>
